header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: sticky;
  top: 0;
}

@media only screen and (max-width: 768px) {
  header {
    flex-direction: column;
  }
}