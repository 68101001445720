.radio__container {
  padding: 10px;
}

.radio__option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.radio__disabled {
  opacity: 0.5;
}