.main-section {
  display: flex;
  flex-direction: column;
}

.selectors {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0px;
}

.actions, .error-state {
  padding: 20px;
}

button {
  background-color: #ff3417;
  color: white;
  font-size: 14px;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

button.disabled {
  opacity: 0.3;
}

@media only screen and (max-width: 768px) {
  .selectors {
    flex-wrap: nowrap;
    overflow-y: scroll;
    justify-content: initial;
  }
}