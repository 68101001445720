.planet-selector__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
  width: 150px;
  padding: 20px;
  margin: 20px;
  border: 1px solid black;
  border-radius: 4px;
}

.pending {
  background-color: yellow; 
}

.selected {
  background-color: lightgreen; 
}

.unassignable {
  background-color: red;
}

@media only screen and (max-width: 768px) {
  .planet-selector__container {
    min-width: 40vw;
  }

  .planet-selector__container:first-child {
    margin-left: 0px;
  }

  .planet-selector__container:last-child {
    margin-right: 0px;
  }
}